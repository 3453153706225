import { FC, ChangeEvent, useState } from 'react';
import { format } from 'date-fns';

import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader
} from '@mui/material';
import Label from 'src/components/Label';
// import { event, eventStatus } from 'src/models/events';
import { ViewAgenda, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Transaction, TransactionStatus } from 'src/models/Transaction';
import TableBodySkeleton from 'src/skeleton/TableSkeleton/TableBodySkeleton';

interface EventsTableProps {
  className?: string;
  events: Transaction[]; // Changed from cryptoOrders to events
}

interface Filters {
  status?: TransactionStatus;
}

const getStatusLabel = (eventStatus: string): JSX.Element => {
  const map: Record<
    string,
    {
      text: string;
      color: 'error' | 'success' | 'warning' | 'primary' | 'secondary';
    }
  > = {
    unpaid: {
      text: 'Unpaid',
      color: 'error' // 'error' is valid
    },
    paid: {
      text: 'Paid',
      color: 'success' // 'success' is valid
    },
    expired: {
      text: 'Expired',
      color: 'secondary' // 'warning' is valid
    },
    refunded: {
      text: 'Expired',
      color: 'warning' // 'warning' is valid
    }
  };

  // Fallback in case the eventStatus doesn't match any in the map
  const { text, color } = map[eventStatus] || {
    text: 'Unknown',
    color: 'secondary'
  };

  return <Label color={color}>{text}</Label>;
};

const applyFilters = (
  transaction: Transaction[],
  filters: Filters
): Transaction[] => {
  return transaction.filter((event) => {
    let matches = true;

    if (filters.status && event.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const isContainsInPathName = (pathName: string): boolean => {
  if (location.pathname.includes(pathName)) {
    return true;
  }
  return false;
};

const applyPagination = (
  events: Transaction[],
  page: number,
  limit: number
): Transaction[] => {
  return events.slice(page * limit, page * limit + limit);
};

const EventsTable = ({ tickets, isLoading }) => {
  const [selectedTransac, setselectedTransac] = useState<string[]>([]);
  const selectedBulkActions = selectedTransac.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  const navigate = useNavigate();

  const handleNavigate = (eventId: string) => {
    navigate(`/management/event/details/${eventId}`); // Assuming you want to navigate with event ID
  };

  const statusOptions = [
    { id: 'all', name: 'All' },
    { id: 'unpaid', name: 'Unpaid' },
    { id: 'paid', name: 'Paid' },
    { id: 'expired', name: 'Expired' },
    { id: 'refunded', name: 'Refunded' }
  ];

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredTransactions = applyFilters(tickets, filters);
  const paginatedTrans = applyPagination(filteredTransactions, page, limit);
  const theme = useTheme();

  const formatDuration = (totalMinutes: number) => {
    const days = Math.floor(totalMinutes / (24 * 60)); // Calculate total days
    const hours = Math.floor((totalMinutes % (24 * 60)) / 60); // Calculate remaining hours
    const minutes = totalMinutes % 60; // Calculate remaining minutes

    return `${days}d ${hours}h ${minutes}m`; // Return the formatted string
  };

  return (
    <Card>
      {selectedBulkActions && (
        <Box flex={1} p={2}>
          {/* <BulkActions /> */}
        </Box>
      )}
      {!selectedBulkActions && (
        <CardHeader
          action={
            <Box width={150}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
          title="Recent Events"
        />
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Name</TableCell>
              {isContainsInPathName('/management/transactions') && (
                <TableCell>Event Name</TableCell>
              )}
              <TableCell>Order ID</TableCell>
              <TableCell>Ticket</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Status</TableCell>
              {/* <TableCell align="right">Actions</TableCell> */}
            </TableRow>
          </TableHead>

          {isLoading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <TableBodySkeleton />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {paginatedTrans.map((trans) => {
                const isTransSelected = selectedTransac.includes(trans.id);
                return (
                  <TableRow hover key={trans.id} selected={isTransSelected}>
                    {/* <TableCell padding="checkbox">
                    <img
                      src={event?.thumbnail_url}
                      alt="Event Thumbnail"
                      className="w-[80px] mt-1 mb-1 mx-auto"
                    />
                  </TableCell> */}
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {trans?.user_name}
                      </Typography>
                    </TableCell>
                    {isContainsInPathName('/management/transactions') &&
                      (trans?.event_name ? (
                        <TableCell>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {trans?.event_name}
                          </Typography>
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      ))}

                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {trans?.order_number}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {trans?.ticket}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {format(
                          new Date(trans?.purchase_date),
                          'MMMM dd, yyyy, hh:mm a'
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        £{trans?.amount}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {getStatusLabel(trans?.status)}
                    </TableCell>
                    {/* <TableCell align="right">
                    <Tooltip title="View Event" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        onClick={() => handleNavigate(trans?.id)} // Pass the event ID for navigation
                      >
                        <Visibility fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredTransactions.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

EventsTable.defaultProps = {
  events: []
};

export default EventsTable;
